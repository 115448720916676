import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import IonHeaderComp from "../../components/utils/IonHeaderComp";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../services/redux/rootReducer";
import { Photo } from "../../models";
import Settings from "../../services/utils/Settings";
import ImageInfoRow from "./ImageInfoRow";
import { deleteConfirm } from "../../services/utils/Sweat";
import { GetRequest, PostRequest } from "../../services/api/GET_POST";
import { deletePhoto } from "../../services/redux/Albums_Slice";

const ImageInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const showBack: number = 2;
  const showLogOut: number = 0;
  const [selectedImage, setSelectedImage] = useState<Photo>({} as Photo);
  const { currentAlbum, selectedAlbum } = useSelector((state: RootState) => state.Albums);

  useEffect(() => {
    const selectedImage = currentAlbum.find((item) => item.id == parseInt(id));
    if (selectedImage) setSelectedImage(selectedImage);
  }, [currentAlbum]);

  const deleteImage = () => {
    deleteConfirm("Delete Image", "Are you sure you want to delete this image?").then((result) => {
      if (result) {
        dispatch(GetRequest("deletePhoto?id=" + id, deletePhoto));
        history.push("/album/" + selectedAlbum.id);
        console.log("Delete Image : ", selectedImage);
      }
    });
  };

  return (
    <IonPage>
      <IonHeaderComp text="firumu" showBack={showBack} showLogOut={showLogOut} />
      <IonContent fullscreen>
        <div className="material-symbols-outlined text-[#666] absolute text-[30px] right-[10px] mt-[10px]" onClick={() => deleteImage()}>
          delete
        </div>
        <div className="material-symbols-outlined text-[#666] absolute text-[30px] right-[50px] mt-[10px]">edit</div>
        <div className="pt-[0px] pb-[100px]">
          <img src={Settings.Get_Image_Url() + selectedImage.url} className="w-[100%] overflow-hidden cursor-pointer" />
          <ImageInfoRow title="CAMERA" description={selectedImage.camera} />
          <ImageInfoRow title="LENSE" description={selectedImage.lense} />
          <ImageInfoRow title="ISO RATED AT" description={selectedImage.isoRate} />
          <ImageInfoRow title="F-STOP" description={selectedImage.f_stop} />
          <ImageInfoRow title="SHUTTER SPEED" description={selectedImage.sutterSpeed} />
          <ImageInfoRow title="OVER EXPOSE" description={selectedImage.overExpose} />
          <ImageInfoRow title="AVERAGE BETWEEN LIGHT AND SHADOW" description={selectedImage.avg_light_shadow} />
          <ImageInfoRow title="FILM" description={selectedImage.film} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ImageInfo;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
