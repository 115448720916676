import React, { useEffect } from "react";
import { Album } from "../../../models";
import { useDispatch, useSelector } from "react-redux";
import Settings from "../../../services/utils/Settings";
import moment from "moment";
// import { NavigateToUrl } from "../../../services/utils/NavigateToUrl";
import { useHistory } from "react-router-dom";
import { GetAlbumName } from "../../../services/redux/Albums_Slice";

interface ContainerProps {
  id: number;
  image: string;
  text: string;
  createdAt: string;
  type: number;
}

const SingleImageCube: React.FC<ContainerProps> = ({ id, image, text, createdAt, type }) => {
  let created: any;
  const dispatch = useDispatch<any>();
  if (type == 1) created = moment(createdAt).format("DD.MM.YYYY");

  const img = "http://127.0.0.1:8000/uploads/2.jpg";
  const history = useHistory();

  const NavigateToSinglePage = async () => {
    console.log("SingleImageCube ", id, image, text, createdAt, type);
    if (type == 1) {
      const album: Album = { id: id, name: text, created_at: createdAt, updated_at: createdAt, img: "" };
      const res = await dispatch(GetAlbumName(album));
      history.push("/album/" + id);
    } else {
      history.push("/imageInfo/" + id);
    }
  };

  return (
    <div className="!bg-black-400" onClick={NavigateToSinglePage}>
      <div className="relative">
        <div className={`z-0 relative hover:opacity-100 ${type == 1 ? "opacity-50" : "opacity-100"}`}>
          <img src={Settings.Get_Image_Url() + image} className="w-[100%] h-[122px] overflow-hidden cursor-pointer" />
        </div>
        <div className="absolute top-[30%] z-1 w-full text-center font-bold text-blue-800 cursor-pointer">
          {type == 1 && <div>{text}</div>}
          {created && <div>{created}</div>}
        </div>
      </div>
    </div>
  );
};

export default SingleImageCube;
