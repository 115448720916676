import Swal from "sweetalert2";

export const deleteConfirm = (title: string, description: string, confirmbuttonText = "delete") => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "<span style='color:#221F73; text-align:right; direction:rtl !important; position:relative;'>" + title + "?</span>",
      html: "<span style='color:#221F73; text-align:right; direction:rtl; position:relative; font-size:14px;top:-12px !important; width:120%'>" + description + "?</span>",
      icon: "warning",
      heightAuto: false,
      showCancelButton: true,
      confirmButtonColor: "#221F73",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmbuttonText,
      cancelButtonText: "cancel",
    }).then(async (result) => {
      resolve(result.isConfirmed);
    });
  });
};

export const saveDetails = (title: string, description: string) => {
  return new Promise((resolve: any, reject: any) => {
    Swal.fire({
      title: title,
      heightAuto: false,
      text: description,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "שמור",
      cancelButtonText: "לא לשמור שינויים",
    }).then(async (result: any) => {
      resolve(result.isConfirmed);
    });
  });
};

export const Success = (message: string, description: string) => {
  Swal.fire({
    title: message,
    text: description == "" ? message : description,
    heightAuto: false,
    icon: "success",
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "סגור",
  });
};

export const Warning = (title: string, text: string) => {
  Swal.fire({
    title: title,
    //text: text,
    html: `<div style="direction:rtl;font-size:20px;font-family:Iran,Calibri;font-weight:bold;">${text}</div>`,
    icon: "warning",
    heightAuto: false,
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "סגור",
  });
};

export const Error = (title: string, text: string) => {
  Swal.fire({
    icon: "error",
    title: title,
    heightAuto: false,
    text: text,
    // footer: '<a href="">Why do I have this issue?</a>'
  });
};

// export const createToast = async (message: string) => {
//   let options = {
//     message,
//     duration: 3000,
//     cssClass: "toast",
//   };

//   const toast = await this.toastCtrl.create(options);
//   await toast.present();
// };
