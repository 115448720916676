import React from "react";
import { IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import IonHeaderComp from "../../components/utils/IonHeaderComp";
import MainPageBanner from "../MainPage/Components/Main_Page_Banner";
import Main_Page_Sort_By from "../MainPage/Components/Main_Page_Sort_By";
import { useParams } from "react-router";
import AddPhotoForm from "./AddPhotoForm";

const AddPhotoPage: React.FC = () => {
  const showBack: number = 2;
  const showLogOut: number = 0;
  return (
    <IonPage>
      <IonHeaderComp text="firumu" showBack={showBack} showLogOut={showLogOut} />
      <IonContent fullscreen>
        <MainPageBanner name="Add Photo" />
        <AddPhotoForm />
      </IonContent>
    </IonPage>
  );
};

export default AddPhotoPage;
