import e from "express";

const Settings = {
  IsProd: 1,
  GLOBAL_URL: "https://tapper.co.il/firumu/public/api/",
  LOCAL_URL: "http://127.0.0.1:8000/api/",
  USER_ID: 1,
  Demo_Img_Url: "../assets/images/demo.jpg",
  Image_URL: "http://127.0.0.1:8000/uploads/",
  Image_GLOBAL_URL: "https://tapper.co.il/firumu/public/uploads/",
  Get_Url: () => {
    if (Settings.IsProd == 1) {
      return Settings.GLOBAL_URL;
    } else {
      return Settings.LOCAL_URL;
    }
  },
  Get_Image_Url: () => {
    if (Settings.IsProd == 1) {
      return Settings.Image_GLOBAL_URL;
    } else {
      return Settings.Image_URL;
    }
  },
};

export default Settings;
