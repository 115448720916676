import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../services/redux/rootReducer";
import { connectedUser } from "../../models";
import { Warning } from "../../services/utils/Sweat";
import { validateEmail } from "../../services/utils/Functions";
import { PostRequest } from "../../services/api/GET_POST";
import { loginUser } from "../../services/redux/Albums_Slice";
import { AppThunk } from "../../services/redux/store";
import { IonPage } from "@ionic/react";

const LoginPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const { connectedUser } = useSelector((state: RootState) => state.Albums);
  const [item, setcurrenItem] = useState<connectedUser>({
    id: 0,
    name: "",
    mail: "",
    password: "",
    confirm: "",
  } as connectedUser);

  const onHandleChange = (e: any) => {
    const newItem = { ...item, [e.target.id]: e.target.value };
    setcurrenItem(newItem);
  };

  const goToRegister = () => {
    history.push("/register/");
  };

  const onSubmit = () => {
    if (validate()) {
      dispatch(PostRequest("loginUser", item, loginUser));
    }
  };

  const validate = () => {
    if (!validateEmail(item.mail)) {
      Warning("Register Error", "Mail is not valid");
      return false;
    }

    if (item.password.length < 6) {
      Warning("Register Error", "Password must be at least 6 characters");
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log("Connected User : ", connectedUser);
    if (connectedUser.id > 0) {
      history.push("/page/Inbox");
    }
  }, [connectedUser]);

  return (
    <IonPage>
      <div className="bg-[#6da6c3]">
        <div className="py-[60px] px-[50px]">
          <img src="../assets/images/work-film.png" className="w-[100%]" />
        </div>

        <div className="p-[30px]">
          <div className="!text-left  w-[100%] ">
            <input type="mail" id="mail" onChange={(e) => onHandleChange(e)} className="p-2 mt-3 text-center bg-[#f5f5f5] w-[100%] focus:outline-none" placeholder="please enter name" required />
          </div>

          <div className="!text-left  w-[100%] ">
            <input type="password" id="password" onChange={(e) => onHandleChange(e)} className="p-2 mt-3 text-center bg-[#f5f5f5] w-[100%] focus:outline-none" placeholder="please enter mail" required />
          </div>

          <div className="px-[30px] pt-[40px] ">
            <div className="border-[2px] border-[#eac3c4] px-[20px] py-[10px] text-center rounded-3xl" onClick={() => onSubmit()}>
              Login
            </div>
          </div>
          <div className="text-center text-white text-[14px] font-bold mt-[5px] cursor-pointer">FORGOT PASSWORD?</div>
        </div>
      </div>
      <div className="bg-[#eac3c4] px-[30px] pt-[40px]">
        <div className="text-center text-black text-[14px] h-[1000px]  mt-[5px] cursor-pointer" onClick={() => goToRegister()}>
          NEW USER?
        </div>
      </div>
    </IonPage>
  );
};

export default LoginPage;
