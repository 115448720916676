import React from "react";
import { IonButtons, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../services/redux/rootReducer";
import Settings from "../../services/utils/Settings";
import { deleteConfirm } from "../../services/utils/Sweat";
import { clearConnctedItem } from "../../services/redux/Albums_Slice";

interface ContainerProps {
  text: string;
  showBack: number;
  showLogOut: number;
}

const IonHeaderComp: React.FC<ContainerProps> = ({ text, showBack = 0, showLogOut = 0 }) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const { selectedAlbum } = useSelector((state: RootState) => state.Albums);

  const NavigateToSinglePage = (type: number) => {
    console.log("showBack : ", showBack);
    if (type == 0) {
      if (showBack == 1) history.push("/page/inbox/");
      if (showBack == 2) history.push("/album/" + selectedAlbum.id);
    }
    if (type == 1) {
      deleteConfirm("LOGOUT ", "Are you sure you want to logout", "accept").then((result) => {
        if (result) {
          console.log("LOGOUT");
          localStorage.removeItem("userId");
          Settings.USER_ID = 0;
          dispatch(clearConnctedItem());
          history.push("/login/");
        }
      });
    }
  };

  return (
    <div className="container">
      <IonHeader>
        <div className="grid grid-cols-6 p-2 bg-white">
          {showBack > 0 && (
            <div className={!showBack ? "col-span-2" : "col-span-0"} onClick={() => NavigateToSinglePage(0)}>
              <div className="material-symbols-outlined !text-right">arrow_back_ios</div>
            </div>
          )}
          {showLogOut > 0 && (
            <div className={!showBack ? "col-span-2" : "col-span-0"} onClick={() => NavigateToSinglePage(1)}>
              <div className="material-symbols-outlined !text-right">logout</div>
            </div>
          )}
          <div className={showBack ? "col-span-4" : "col-span-8"}>
            <div className="w-full text-center text-[20px] text-bold">{text}</div>
          </div>
        </div>
      </IonHeader>
    </div>
  );
};

export default IonHeaderComp;
