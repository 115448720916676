import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connectedUser } from "../../models";
import { Warning } from "../../services/utils/Sweat";
import { validateEmail } from "../../services/utils/Functions";

import { GetRequest, PostRequest } from "../../services/api/GET_POST";
import { registerUser } from "../../services/redux/Albums_Slice";
import { RootState } from "../../services/redux/rootReducer";
import { IonPage } from "@ionic/react";

const RegisterPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const { connectedUser } = useSelector((state: RootState) => state.Albums);
  const [item, setcurrenItem] = useState<connectedUser>({
    id: 0,
    name: "",
    mail: "",
    password: "",
    confirm: "",
  } as connectedUser);

  useEffect(() => {
    if (connectedUser.id > 0) {
      history.push("/page/Inbox");
    }
  }, [connectedUser]);

  const onHandleChange = (e: any) => {
    const newItem = { ...item, [e.target.id]: e.target.value };
    setcurrenItem(newItem);
  };

  const onSubmit = () => {
    if (validate()) {
      dispatch(PostRequest("registerUser", item, registerUser));
    }
  };

  const goToLogin = () => {
    history.push("/login/");
  };

  const validate = () => {
    if (item.name.length < 3) {
      Warning("Register Error", "Name must be at least 3 characters");
      return false;
    }

    if (!validateEmail(item.mail)) {
      Warning("Register Error", "Mail is not valid");
      return false;
    }

    if (item.password.length < 6) {
      Warning("Register Error", "Password must be at least 6 characters");
      return false;
    }

    if (item.password != item.confirm) {
      Warning("Register Error", "Password and confirm password must be same");
      return false;
    }

    return true;
  };

  return (
    <IonPage>
      <div className="bg-[#6da6c3]">
        <div className="py-[60px] px-[50px]">
          <img src="../assets/images/work-film.png" className="w-[100%]" />
        </div>

        <div className="p-[30px]">
          <div className="!text-left  w-[100%] ">
            <input type="text" id="name" onChange={(e) => onHandleChange(e)} className="p-2 text-center bg-[#f5f5f5] w-[100%] focus:outline-none" placeholder="please enter name" required />
          </div>

          <div className="!text-left  w-[100%] ">
            <input type="mail" id="mail" onChange={(e) => onHandleChange(e)} className="p-2 mt-3 text-center bg-[#f5f5f5] w-[100%] focus:outline-none" placeholder="please enter mail" required />
          </div>

          <div className="!text-left  w-[100%] ">
            <input type="password" id="password" onChange={(e) => onHandleChange(e)} className="p-2 mt-3 text-center bg-[#f5f5f5] w-[100%] focus:outline-none" placeholder="please enter password" required />
          </div>

          <div className="!text-left  w-[100%] ">
            <input type="password" id="confirm" onChange={(e) => onHandleChange(e)} className="p-2 mt-3 text-center bg-[#f5f5f5] w-[100%] focus:outline-none" placeholder="please confirm password" required />
          </div>

          <div className="px-[30px] pt-[40px] ">
            <div className="border-[2px] border-[#eac3c4] px-[20px] py-[10px] text-center rounded-3xl" onClick={() => onSubmit()}>
              REGISTER
            </div>
          </div>
          <div className="text-center text-white text-[14px] font-bold mt-[5px] cursor-pointer">FORGOT PASSWORD?</div>
        </div>
      </div>
      <div className="bg-[#eac3c4] px-[30px] pt-[40px]">
        <div className="text-center text-black text-[14px] h-[1000px]  mt-[5px] cursor-pointer" onClick={() => goToLogin()}>
          BACK TO LOGIN?
        </div>
      </div>
    </IonPage>
  );
};

export default RegisterPage;
